import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { BUILDER_ROUTING } from '@features/partners/builder/routes';
import { MenuListService } from '@features/partners/builder/settings/menu/menu-list/menu-list.service';
import { MENU_ROUTING } from '@features/partners/builder/settings/menu/routes/menu.routes';
import { SETTINGS_ROUTING } from '@features/partners/builder/settings/routes';
import { FEATURES_ROUTING } from '@features/routes';
import { DESKTOP_MENU_TYPE } from '@models/partner/menu/plan-group-menu-response';
import { PlansWebservice } from '@webservices/plans/plans.webservice';
import { Observable, map, tap } from 'rxjs';

export const canActivateDesktopMenuPage = ({
  paramMap,
  queryParamMap,
}: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | UrlTree => {
  const partnerId = paramMap.get('partnerId');
  const router = inject(Router);
  const menuListService = inject(MenuListService);
  const getPlanGroups = inject(PlansWebservice).getPlanGroupResource;

  if (typeof partnerId !== 'string') {
    return router.createUrlTree(['/']);
  }

  menuListService.setPartnerId(partnerId);
  menuListService.setMenuType(DESKTOP_MENU_TYPE);

  const planGroupId = queryParamMap.get('planGroupId');

  return getPlanGroups(partnerId).pipe(
    tap((planGroups) => menuListService.setPlanGroups(planGroups)),
    map((planGroups) => planGroups.map(({ id }) => id)),
    map((planGroupIds) => {
      if (planGroupId === null || !planGroupIds.includes(planGroupId)) {
        menuListService.setCurrentPlanGroupId(planGroupIds[0]);
        return router.createUrlTree(
          [
            '/',
            FEATURES_ROUTING.Partners,
            partnerId,
            BUILDER_ROUTING.Settings,
            SETTINGS_ROUTING.Menu,
            MENU_ROUTING.DESKTOP,
          ],
          { queryParams: { planGroupId: planGroupIds[0] } }
        );
      }
      menuListService.setCurrentPlanGroupId(planGroupId);
      return true;
    })
  );
};
