import { RegisterIcon } from './menu-icons';
import { NavigationItemStatus } from './plan-group-menu-response';

export const EXTERNAL_LINK_TYPE = 'external';
export const INTERNAL_LINK_TYPE = 'internal';

export const LINK_TYPES = [INTERNAL_LINK_TYPE, EXTERNAL_LINK_TYPE] as const;

export type LinkType = (typeof LINK_TYPES)[number];

export type NavigationItemResponse = {
  id: string;
  label: string;
} & (ChildNavigation | MobileNavigation | ParentNavigation);

type NavigationItem = {
  status: NavigationItemStatus;
  link: LinkResponse;
  parentId: string | null;
};

type ChildNavigation = NavigationItem & {
  type: 'child';
};

export type ParentNavigation = NavigationItem & {
  type: 'parent';
  children: Array<ChildNavigation>;
};

type MobileNavigation = NavigationItem & {
  type: 'mobile';
  icon: RegisterIcon;
};

type ExternalLinkResponse = {
  type: typeof EXTERNAL_LINK_TYPE;
  target: '_self' | '_blank';
  url: string;
};

type InternalLinkResponse = {
  type: typeof INTERNAL_LINK_TYPE;
  target: '_self';
  url: string;
};

export type LinkResponse = ExternalLinkResponse | InternalLinkResponse | null;

export const DEFAULT_NAVIGATION_DESKTOP_ITEM: Omit<NavigationItemResponse, 'id'> = {
  type: 'parent',
  label: '',
  status: 'draft',
  link: null,
  parentId: null,
} as const;

export const DEFAULT_NAVIGATION_MOBILE_ITEM: Omit<NavigationItemResponse, 'id'> = {
  type: 'mobile',
  label: '',
  status: 'draft',
  link: null,
  parentId: null,
} as const;

export type CreateNavigationItemRequest = {
  status: NavigationItemStatus;
  label: string;
  link: LinkResponse;
  icon: RegisterIcon | null;
  parentId: string | null;
};
